import Head from 'next/head'
import { NextPage } from 'next'
import React from 'react'
import { LockOutlined, UserOutlined } from '@ant-design/icons/lib'
import { Alert, Button, Card, Col, Form, Input, Row } from 'antd'
import {
  InitialStateType,
  LoginPayload,
  actions,
} from '../containers/profile/slice'
import { useDispatch, useSelector } from 'react-redux'

const LoginPage: NextPage = () => {
  const { isProcessLogin, errorLogin } = useSelector(
    (state: { profile: InitialStateType }) => state.profile,
  )

  const dispatch = useDispatch()

  const onFinish = (values: LoginPayload): void => {
    dispatch(actions.login(values))
  }

  return (
    <>
      <Head>
        <title>{process.env.NEXT_PUBLIC_APP_NAME} - Авторизация</title>
      </Head>
      <Row style={{ height: '100vh' }} justify="space-around" align="middle">
        <Col style={{ width: 350 }}>
          <Card>
            <Form name="basic" onFinish={onFinish} style={{ minWidth: 250 }}>
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Введите E-mail или мобильный телефон!',
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="E-mail"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Введите пароль!' }]}
              >
                <Input.Password
                  prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Пароль"
                />
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%' }}
                  loading={isProcessLogin}
                >
                  Войти
                </Button>
              </Form.Item>
            </Form>
            {errorLogin ? (
              <Alert
                message={errorLogin}
                type="error"
                style={{ marginTop: 15, textAlign: 'center' }}
              />
            ) : null}
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default LoginPage
