import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoginDataType, ProfileType } from './types'

export type InitialStateType = {
  profile: ProfileType | null
  isAllowEdit: boolean
  isProcessLogin: boolean
  isProcessFetchProfile: boolean
  isProcessSignOut: boolean
  errorLogin: string | null
  errorProfile: string | null
  errorSignOut: string | null
}

export type LoginPayload = LoginDataType
export type LoginSuccessPayload = undefined
export type LoginFailurePayload = string | null

export type FetchProfilePayload = undefined
export type FetchProfileSuccessPayload = ProfileType
export type FetchProfileFailurePayload = string | null

export type SignOutPayload = undefined
export type SignOutSuccessPayload = undefined
export type SignOutFailurePayload = string | null

const initialState: InitialStateType = {
  profile: null,
  isAllowEdit: false,
  isProcessLogin: false,
  isProcessFetchProfile: false,
  isProcessSignOut: false,
  errorLogin: null,
  errorProfile: null,
  errorSignOut: null,
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    login(state: InitialStateType, _action: PayloadAction<LoginPayload>): void {
      state.isProcessLogin = true
    },
    loginSuccess(
      state: InitialStateType,
      _action: PayloadAction<LoginSuccessPayload>,
    ): void {
      state.isProcessLogin = false
    },
    loginFailure(
      state: InitialStateType,
      action: PayloadAction<LoginFailurePayload>,
    ): void {
      state.isProcessLogin = false
      state.errorLogin = action.payload
    },
    fetchProfile(
      state: InitialStateType,
      _action: PayloadAction<FetchProfilePayload>,
    ): void {
      state.isProcessFetchProfile = true
    },
    fetchProfileSuccess(
      state: InitialStateType,
      action: PayloadAction<FetchProfileSuccessPayload>,
    ): void {
      state.profile = action.payload
      state.isAllowEdit = action.payload.roles.includes('admin')
      state.isProcessFetchProfile = false
    },
    fetchProfileFailure(
      state: InitialStateType,
      action: PayloadAction<FetchProfileFailurePayload>,
    ): void {
      state.isProcessFetchProfile = false
      state.errorProfile = action.payload
    },
    signOut(
      state: InitialStateType,
      _action: PayloadAction<SignOutPayload>,
    ): void {
      state.isProcessSignOut = true
    },
    signOutSuccess(
      state: InitialStateType,
      _action: PayloadAction<SignOutSuccessPayload>,
    ): void {
      state.isProcessSignOut = false
    },
    signOutFailure(
      state: InitialStateType,
      action: PayloadAction<SignOutFailurePayload>,
    ): void {
      state.isProcessSignOut = false
      state.errorSignOut = action.payload
    },
  },
})

export const actions = profileSlice.actions

export default profileSlice.reducer
